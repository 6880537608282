interface GetStyleProps {
  hide_desktop_image: boolean;
  hide_mobile_image: boolean;
}

interface HeroSecondaryStyle {
  mainDiv: string;
  mainContent: string;
  title: string;
  titleWithoutPopper: string;
  descriptionWithoutPopper: string;
  applyButton: string;
  applyButtonChild: string;
  secondaryButton: string;
  applyMobileButton: string;
  trustPilotImage: string;
  bottomText: string;
  box: string;
  boxContent: string;
  boxPrimaryLink: string;
  boxSecondaryLink: string;
  bulletPointSection: string;
  bulletPoint: string;
  imageBlock: string;
}

export const getStyle = (
  variation:
    | 'default'
    | 'heroSecondaryImgLeft'
    | 'heroSecondaryImgRight'
    | 'reducedVariation'
    | 'prequalFlow',
  props: GetStyleProps
): HeroSecondaryStyle => {
  const styleObject = styles(props);
  return styleObject[variation] || styleObject.default;
};

const styles = (
  props: GetStyleProps
): { [key: string]: HeroSecondaryStyle } => {
  return {
    default: {
      mainDiv:
        'flex flex-col-reverse md:flex-row w-full max-w-[1440px] items-center',
      mainContent: `grid px-4 my-6 mt-0 w-full md:mt-[20px] ${!props.hide_desktop_image ? 'md:w-1/2' : ''}`,
      title: 'mt-5',
      titleWithoutPopper: 'mt-6 text-[14px]',
      descriptionWithoutPopper: 'my-2',
      applyButton: 'flex flex-col md:flex-row',
      applyButtonChild: 'mr-0 md:mr-2 mt-2 md:mt-0',
      secondaryButton: 'mr-0 md:mr-2 mt-2 md:mt-0',
      applyMobileButton: 'flex flex-col block md:hidden px-4 py-3',
      trustPilotImage: '',
      bottomText: 'mt-4',
      box: 'mt-4 w-full md:w-[96%]',
      boxContent: 'flex flex-col md:flex-row',
      boxPrimaryLink:
        'mr-6 mb-3 text-SnapBlue font-bold see-all-link secondaryHeavyFont hover:underline',
      boxSecondaryLink:
        'mr-6 text-SnapBlue font-bold see-all-link secondaryHeavyFont hover:underline',
      bulletPointSection: 'mt-4',
      bulletPoint: 'flex flex-row [&_.bodyFont]:mb-0 mt-3',
      imageBlock: 'images-block w-full md:w-1/2',
    },
    heroSecondaryImgLeft: {
      mainDiv:
        'flex w-full flex-col-reverse max-w-[1440px] md:flex-row-reverse items-center',
      mainContent: `grid px-4 my-6 mt-0 w-full md:mt-[20px] ${
        !props.hide_desktop_image ? 'md:w-1/2' : ''
      }`,
      title: 'mt-5',
      titleWithoutPopper: 'mt-6',
      descriptionWithoutPopper: 'my-2',
      applyButton: 'flex flex-col md:flex-row',
      applyButtonChild: 'mr-0 md:mr-2 mt-2 md:mt-0',
      secondaryButton: 'mr-0 md:mr-2 mt-2 md:mt-0',
      applyMobileButton: 'flex flex-col block md:hidden px-4 py-3',
      trustPilotImage: '',
      bottomText: 'mt-4 ',
      box: 'mt-4 w-full md:w-[96%]',
      boxContent: 'flex flex-col md:flex-row',
      boxPrimaryLink:
        'mr-6 mb-3 text-SnapBlue font-bold see-all-link secondaryHeavyFont hover:underline',
      boxSecondaryLink:
        'mr-6 text-SnapBlue font-bold see-all-link secondaryHeavyFont hover:underline',
      bulletPointSection: 'mt-4',
      bulletPoint: 'flex flex-row [&_.bodyFont]:mb-0 mt-3',
      imageBlock: 'images-block w-full md:w-1/2',
    },
    heroSecondaryImgRight: {
      mainDiv:
        'flex w-full flex-col-reverse max-w-[1440px] md:flex-row items-center',
      mainContent: `grid px-4 my-6 w-full mt-0 md:mt-[20px] lg:w-2/3 lg:pr-2 ${!props.hide_desktop_image ? 'md:w-1/2' : ''}`,
      title: 'mt-5',
      titleWithoutPopper: 'mt-0',
      descriptionWithoutPopper: 'my-2',
      applyButton: 'flex flex-col md:flex-row mt-5',
      applyButtonChild: 'mr-0 md:mr-2 mt-2 md:mt-0',
      secondaryButton: 'mr-0 md:mr-2 mt-2 md:mt-0',
      applyMobileButton: 'flex flex-col block md:hidden px-4 py-3',
      trustPilotImage: 'md:mt-4 text-center md:text-left',
      bottomText: 'mt-4',
      box: 'mt-4 w-full md:w-[96%]',
      boxContent: 'flex flex-col md:flex-row',
      boxPrimaryLink:
        'mr-6 mb-3 text-SnapBlue font-bold see-all-link secondaryHeavyFont hover:underline',
      boxSecondaryLink:
        'mr-6 text-SnapBlue font-bold see-all-link secondaryHeavyFont hover:underline',
      bulletPointSection: 'mt-0 text-sm md:text-base',
      bulletPoint: 'flex flex-row [&_.bodyFont]:mb-0 mt-1',
      imageBlock: 'images-block w-full md:w-3/5 pt-4 md:pt-0',
    },
    reducedVariation: {
      mainDiv:
        'flex w-full max-w-[1312px] md:flex-row items-start md:items-end justify-between md:pl-0',
      mainContent: `grid px-4 my-6 w-7/12 lg:w-8/12 xxl:w-[850px] lg:pl-0 ${
        props.hide_mobile_image ? 'w-full lg:pl-0 lg:px-0' : ''
      } ${props.hide_desktop_image ? 'xxl:w-full lg:px-[1rem]' : ''}`,
      title: 'mt-5',
      titleWithoutPopper: 'sm:w-[calc(100%+3rem)]',
      descriptionWithoutPopper: 'my-2 [&>*]:font-normal',
      applyButton: 'flex flex-col md:flex-row',
      applyButtonChild: 'mr-0 md:mr-2 mt-2 md:mt-0',
      secondaryButton: 'mr-0 md:mr-2 mt-2 md:mt-0',
      applyMobileButton: 'flex flex-col block md:hidden px-4 py-3',
      trustPilotImage: '',
      bottomText: 'mt-4',
      box: 'mt-4',
      boxContent: 'flex flex-col md:flex-row',
      boxPrimaryLink:
        'mr-6 mb-3 text-SnapBlue font-bold see-all-link secondaryHeavyFont hover:underline',
      boxSecondaryLink:
        'mr-6 text-SnapBlue font-bold see-all-link secondaryHeavyFont hover:underline',
      bulletPointSection: 'mt-4',
      bulletPoint: 'flex flex-row [&_.bodyFont]:mb-0 mt-3',
      imageBlock:
        'images-block relative md:-mr-[2.7rem] [&>img]:pl-2 w-5/12 lg:w-4/12 xxl:w-[400px] xxl:w-[0]',
    },
    prequalFlow: {
      mainDiv:
        'flex w-full max-w-[1312px] md:flex-row items-start md:items-end justify-between md:pl-0',
      mainContent: `grid px-4 my-6 w-7/12 lg:w-8/12 xxl:w-[850px] lg:pl-0 ${
        props.hide_mobile_image ? 'w-full lg:pl-0 lg:px-0' : ''
      }`,
      title: 'mt-5',
      titleWithoutPopper: 'sm:w-[calc(100%+3rem)]',
      descriptionWithoutPopper: 'my-2 [&>*]:font-normal',
      applyButton: 'flex flex-col md:flex-row',
      applyButtonChild: 'mr-0 md:mr-2 mt-2 md:mt-0',
      secondaryButton: 'mr-0 md:mr-2 mt-2 md:mt-0',
      applyMobileButton: 'flex flex-col block md:hidden px-4 mt-3',
      trustPilotImage: '',
      bottomText: 'mt-4',
      box: 'mt-4',
      boxContent: 'flex flex-col md:flex-row',
      boxPrimaryLink:
        'mr-6 mb-3 text-SnapBlue font-bold see-all-link secondaryHeavyFont hover:underline',
      boxSecondaryLink:
        'mr-6 text-SnapBlue font-bold see-all-link secondaryHeavyFont hover:underline',
      bulletPointSection: 'mt-4',
      bulletPoint: 'flex flex-row [&_.bodyFont]:mb-0 mt-3',
      imageBlock:
        'images-block relative md:-mr-[2.7rem] [&>img]:pl-2 w-5/12 lg:w-4/12 xxl:w-[400px] xxl:w-[0]',
    },
    // Add more variations as needed
  };
};
