import {
  ColoredTag as Tag,
  TagBackgroundColor,
} from '@ee-monorepo/shared/ui/tag';
import {
  PrismicButton,
  TooltipWithPopper,
  QRCodeDownloadSection,
  PrismicLink,
  ImageWrapper,
} from '@ee-monorepo/prismic/shared/ui';
import * as PrismicFields from '@ee-monorepo/prismic/shared/types';
import cn from 'classnames';
import { getColor } from '@ee-monorepo/shared/utilities/functions';
import {
  heroSecondarySerializer,
  heroSerializer,
} from '@ee-monorepo/prismic/shared/serializers';
import { useEffect } from 'react';
import s from './hero-secondary.module.scss';
import { ButtonAction } from '@ee-monorepo/prismic/shared/types';
import { SolidCard } from '@ee-monorepo/shared/ui/card';
import { OpenInNew } from '@mui/icons-material';
import { PrismicRichText } from '@prismicio/react';
import * as prismicH from '@prismicio/helpers';
import CheckIcon from '@mui/icons-material/Check';
import { useTrackingContext } from '@ee-monorepo/shared/context/tracking';
import { usePrequalExperiment } from '@ee-monorepo/growth-experiments';
import { getStyle } from './hero-secondary.styles';

/* eslint-disable complexity */
export interface HeroSecondaryActionProps extends PrismicFields.SliceBase {
  slice?: {
    variation?:
      | 'default'
      | 'heroSecondaryImgLeft'
      | 'heroSecondaryImgRight'
      | 'reducedVariation'
      | 'prequalFlow';
    primary?: {
      header_title?: PrismicFields.RichTextField;
      header_title_secondary?: PrismicFields.RichTextField;
      show_header_disclaimer?: boolean;
      header_disclaimer_text?: PrismicFields.RichTextField;
      header_body?: PrismicFields.RichTextField;
      prequal_header_body?: PrismicFields.RichTextField;
      show_body_disclaimer?: boolean;
      body_disclaimer_text?: PrismicFields.RichTextField;
      prequal_show_body_disclaimer?: boolean;
      prequal_body_disclaimer_text?: PrismicFields.RichTextField;
      sub_header?: PrismicFields.RichTextField;
      tag_background?: string;
      button_label?: PrismicFields.KeyTextField;
      image?: PrismicFields.Image;
      hide_desktop_image?: boolean;
      hide_mobile_image?: boolean;
      apply_button_url_link?: PrismicFields.Link;
      fixed_position_on_mobile?: boolean;
      bottom_text?: PrismicFields.RichTextField;
      background?: string;
      header_color?: string;
      header_secondary_color?: string;
      header_body_color?: string;
      action?: ButtonAction;
      show_button?: boolean;
      promote_mobile_app?: boolean;
      promote_mobile_title?: PrismicFields.RichTextField;
      qr_logo?: PrismicFields.Image;
      apple_store_logo?: PrismicFields.Image;
      apple_store_link?: {
        url?: string;
      };
      google_store_logo?: PrismicFields.Image;
      google_store_link?: {
        url?: string;
      };
      button_color?: PrismicFields.Select;
      secondary_button_label?: PrismicFields.KeyTextField;
      secondary_button_link?: PrismicFields.Link;
      secondary_button_fixed_mobile?: boolean;
      show_secondary_button?: boolean;
      secondary_button_action?: ButtonAction;
      show_box?: boolean;
      box_title?: PrismicFields.RichTextField;
      box_primary_button_text?: PrismicFields.KeyTextField;
      box_primary_button_link?: PrismicFields.Link;
      box_secondary_button_text?: PrismicFields.KeyTextField;
      box_secondary_button_link?: PrismicFields.Link;
      trustpilot_icon?: PrismicFields.Image;
    };
    items?: [
      {
        bullet_point?: PrismicFields.RichTextField;
        bullet_point_tooltip_text?: PrismicFields.RichTextField;
      }
    ];
  };
}

export const HeroSecondary = ({
  index,
  slice: {
    variation,
    primary: {
      header_title,
      header_title_secondary,
      header_body_color,
      header_body,
      prequal_header_body,
      sub_header,
      tag_background,
      button_label,
      button_color = '',
      background,
      header_color,
      header_secondary_color,
      image,
      hide_desktop_image,
      hide_mobile_image,
      apply_button_url_link,
      fixed_position_on_mobile,
      bottom_text,
      action,
      show_button,
      show_header_disclaimer,
      header_disclaimer_text,
      show_body_disclaimer,
      body_disclaimer_text,
      prequal_show_body_disclaimer,
      prequal_body_disclaimer_text,
      promote_mobile_app,
      promote_mobile_title,
      qr_logo,
      apple_store_logo,
      apple_store_link,
      google_store_logo,
      google_store_link,
      secondary_button_label,
      secondary_button_link,
      secondary_button_fixed_mobile,
      show_secondary_button,
      secondary_button_action,
      show_box,
      box_title,
      box_primary_button_text,
      box_primary_button_link,
      box_secondary_button_text,
      box_secondary_button_link,
      trustpilot_icon,
    },
    items = [{}],
  },
}: HeroSecondaryActionProps) => {
  const { isPrequal } = usePrequalExperiment();
  useEffect(() => {
    if (fixed_position_on_mobile) {
      document.body.classList.add('has-mobile-hero-btn');
    }
    return () => {
      document.body.classList.remove('has-mobile-hero-btn');
    };
  }, [fixed_position_on_mobile]);
  const subHeader = prismicH.asText(sub_header);
  const { tracking } = useTrackingContext();
  const styles = getStyle(variation, { hide_desktop_image, hide_mobile_image });

  return (
    <div
      data-testid="hero-secondary"
      data-slice-index={index}
      className={cn(getColor(background).backgroundColorClass)}
    >
      <div className="snap-container px-2">
        <div className={styles.mainDiv}>
          <div className={styles.mainContent}>
            {!!subHeader && (
              <div
                className={cn('mb-4', 'mt-5')}
                data-testid="hero-secondary-tag"
              >
                <Tag
                  backgroundColor={tag_background as TagBackgroundColor}
                  text={subHeader}
                ></Tag>
              </div>
            )}
            {show_header_disclaimer ? (
              <div data-testid="hero-secondary-title" className={styles.title}>
                <TooltipWithPopper
                  mainContent={header_title}
                  mainContentSerializer={
                    variation === 'reducedVariation' ||
                    variation === 'prequalFlow'
                      ? heroSerializer
                      : heroSecondarySerializer
                  }
                  mainContentColor={getColor(header_color).textColorClass}
                  popperContent={header_disclaimer_text}
                  elementType={header_title[0]?.type}
                />
              </div>
            ) : (
              <>
                <div
                  data-testid="hero-secondary-title-without-popper"
                  className={cn(
                    getColor(header_color).textColorClass,
                    styles.titleWithoutPopper
                  )}
                >
                  <PrismicRichText
                    field={header_title}
                    components={
                      variation === 'reducedVariation' ||
                      variation === 'prequalFlow'
                        ? heroSerializer
                        : heroSecondarySerializer
                    }
                  />
                </div>
                <div
                  data-testid="hero-secondary-title-without-popper-sec"
                  className={cn(
                    getColor(header_secondary_color).textColorClass
                  )}
                >
                  <PrismicRichText
                    field={header_title_secondary}
                    components={heroSecondarySerializer}
                  />
                </div>
              </>
            )}
            {!isPrequal && show_body_disclaimer ? (
              <div data-testid="hero-secondary-description">
                <TooltipWithPopper
                  mainContent={header_body}
                  mainContentSerializer={heroSecondarySerializer}
                  mainContentColor={getColor(header_body_color).textColorClass}
                  popperContent={body_disclaimer_text}
                  elementType={header_body[0]?.type}
                />
              </div>
            ) : isPrequal &&
              variation === 'prequalFlow' &&
              prequal_show_body_disclaimer ? (
              <div data-testid="hero-secondary-description-prequal">
                <TooltipWithPopper
                  mainContent={prequal_header_body}
                  mainContentSerializer={heroSecondarySerializer}
                  mainContentColor={getColor(header_body_color).textColorClass}
                  popperContent={prequal_body_disclaimer_text}
                  elementType={prequal_header_body[0]?.type}
                />
              </div>
            ) : (
              <div
                data-testid="hero-secondary-description-without-popper"
                className={cn(
                  getColor(header_body_color).textColorClass,
                  styles.descriptionWithoutPopper
                )}
              >
                <PrismicRichText
                  field={header_body}
                  components={heroSecondarySerializer}
                />
              </div>
            )}
            <div
              className={styles.applyButton}
              data-testid="apply-button-hero-secondary"
            >
              <div
                className={styles.applyButtonChild}
                data-testid="apply-button-hero-secondary"
              >
                {apply_button_url_link && (
                  <PrismicButton
                    label={button_label}
                    variant="primary"
                    link={apply_button_url_link}
                    className={cn(
                      s.applyButton,
                      fixed_position_on_mobile
                        ? 'md:mr-4 hidden md:flex'
                        : 'w-full',
                      `${button_color}`
                    )}
                    showButton={show_button}
                    actionType={action}
                    onClick={() => {
                      tracking?.logClickEvent({
                        click_value: button_label,
                        click_type: 'cta',
                        placement: 'body',
                      });
                    }}
                  />
                )}
              </div>
              <div
                className={styles.secondaryButton}
                data-testid="secondary-button-hero-secondary"
              >
                {secondary_button_link && (
                  <PrismicButton
                    label={secondary_button_label}
                    variant="secondary"
                    link={secondary_button_link}
                    className={cn(
                      s.applyButton,
                      'w-full',
                      `${button_color ? button_color : ''}`
                    )}
                    showButton={show_secondary_button}
                    actionType={secondary_button_action}
                    onClick={() =>
                      tracking?.logClickEvent({
                        click_value: secondary_button_label,
                        click_type: 'cta',
                        placement: 'body',
                      })
                    }
                  />
                )}
              </div>
            </div>
            {fixed_position_on_mobile && (
              <div
                className={cn(
                  styles.applyMobileButton,
                  s.applyButtonMobileContainer
                )}
                data-testid="apply-mobile-button-hero-secondary"
              >
                {apply_button_url_link && (
                  <PrismicButton
                    label={button_label}
                    link={apply_button_url_link}
                    className={s.applyButton}
                    showButton={show_button}
                    actionType={action}
                    variant="primary"
                    onClick={() => {
                      tracking?.logClickEvent({
                        click_value: button_label,
                        click_type: 'cta',
                        placement: 'bottom anchor',
                      });
                    }}
                  />
                )}
              </div>
            )}
            <div
              className={styles.trustPilotImage}
              data-testid="image-hero-secondary"
            >
              {prismicH.isFilled.image(trustpilot_icon) && (
                <picture
                  data-testid="hero-picture"
                  className={cn('overflow-hidden', 'w-1/5')}
                >
                  <source
                    media="(max-width: 232px)"
                    srcSet={trustpilot_icon?.mobile?.url}
                  />
                  <source srcSet={trustpilot_icon?.url} />
                  <img
                    data-testid="trustpilot_icon"
                    src={image?.url}
                    alt={image?.alt}
                  />
                </picture>
              )}
            </div>
            {bottom_text && (
              <div
                className={styles.bottomText}
                data-testid="hero-secondary-bottom-text"
              >
                <PrismicRichText
                  field={bottom_text}
                  components={heroSecondarySerializer}
                />
              </div>
            )}
            {show_box && (
              <div
                data-testid="hero-secondary-show-box"
                className={cn(
                  getColor(header_body_color).textColorClass,
                  styles.box
                )}
              >
                <SolidCard className={s.solidCard}>
                  <PrismicRichText
                    field={box_title}
                    components={heroSecondarySerializer}
                  />
                  <div className={styles.boxContent}>
                    {box_primary_button_link?.url && (
                      <PrismicLink
                        data-testid="prismic-link-anchor"
                        link={box_primary_button_link}
                        className={styles.boxPrimaryLink}
                        onClick={() =>
                          tracking?.logClickEvent({
                            click_value: box_primary_button_text,
                            click_type: 'link',
                            placement: 'body',
                          })
                        }
                      >
                        <OpenInNew
                          fontSize="inherit"
                          className="mr-1 align-text-bottom"
                        />
                        {box_primary_button_text}
                      </PrismicLink>
                    )}
                    {box_secondary_button_link?.url && (
                      <PrismicLink
                        data-testid="prismic-link-anchor"
                        link={box_secondary_button_link}
                        className={styles.boxSecondaryLink}
                        onClick={() =>
                          tracking?.logClickEvent({
                            click_value: box_secondary_button_text,
                            click_type: 'link',
                            placement: 'body',
                          })
                        }
                      >
                        <OpenInNew
                          fontSize="inherit"
                          className="mr-1 align-text-bottom"
                        />
                        {box_secondary_button_text}
                      </PrismicLink>
                    )}
                  </div>
                </SolidCard>
              </div>
            )}
            {promote_mobile_app && (
              <QRCodeDownloadSection
                variation={'default-slice'}
                promote_mobile_title={promote_mobile_title}
                qr_logo={qr_logo}
                apple_store_logo={apple_store_logo}
                apple_store_link={apple_store_link?.url}
                google_store_logo={google_store_logo}
                google_store_link={google_store_link?.url}
                classes={s.mobilePromotion}
                showMobileScanText
              />
            )}
            {variation !== 'reducedVariation' &&
            variation !== 'prequalFlow' &&
            items.length > 0 ? (
              <div
                className={styles.bulletPointSection}
                data-testid="bullet-point-section"
              >
                {items.length > 0 &&
                  items?.map((item, _index) => {
                    return (
                      item?.bullet_point &&
                      prismicH.isFilled.richText(item?.bullet_point) && (
                        <div
                          className={cn(s.container, styles.bulletPoint)}
                          data-testid={'bullet-point'}
                        >
                          <CheckIcon
                            color="primary"
                            className={
                              variation === 'heroSecondaryImgRight'
                                ? 'mr-2'
                                : 'mx-2'
                            }
                          />
                          <TooltipWithPopper
                            mainContent={item?.bullet_point}
                            mainContentSerializer={heroSecondarySerializer}
                            popperContent={item?.bullet_point_tooltip_text}
                            elementType={item?.bullet_point[0]?.type}
                            mainContentColor="text-SnapDarkBlue"
                            mainContentBold={
                              variation === 'heroSecondaryImgRight'
                                ? 'font-bold mb-0'
                                : 'font-normal'
                            }
                          />
                        </div>
                      )
                    );
                  })}
              </div>
            ) : null}
          </div>

          <div className={styles.imageBlock}>
            {!hide_desktop_image ? (
              <ImageWrapper
                src={image?.url}
                alt={image?.alt}
                width={image?.dimensions.width}
                height={image?.dimensions.height}
                className={'hidden md:inline-block w-full'}
                data-testid="hero-picture"
              />
            ) : null}
            {!hide_mobile_image ? (
              <ImageWrapper
                src={image?.mobile?.url}
                alt={image?.mobile?.alt}
                {...(variation === 'reducedVariation' ||
                variation === 'prequalFlow'
                  ? { fill: true }
                  : {
                      width: image?.mobile?.dimensions.width,
                      height: image?.mobile?.dimensions.height,
                    })}
                className={'inline-block md:hidden w-full'}
                data-testid="hero-picture-mobile"
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};
